/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
// Generated using typescript-generator version 2.24.612 on 2025-02-06 13:41:46.

import { TranslateRequestDTO, AuthenticationRequest, ChangePasswordDTO, CreateAdministratorDTO, UpdateAdministratorDTO, CreateUserDTO, UpdateUserDTO, XTMConnectionParametersDTO, XTMConnectRequest, GetTemplatesDTO, GetCustomersDTO, EntryRequestDTO } from "./restApplicationClientTypeOverrides";

export interface AdministratorDTO extends BaseDTO, Serializable {
    email: string;
    status: boolean;
    clientName: string;
    client: ClientDTO;
}

export interface AuthenticationResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface RefreshTokenResult {
    accessToken: string;
    ttl: number;
    refreshToken: string;
    username: string;
    roles: string[];
}

export interface CreateClientDTO extends Serializable {
    clientName: string;
    status: boolean;
    enabledTags: boolean;
    enabledEntryLevelLocalization: boolean;
    enabledOldHyperLinks: boolean;
    nonHumanTranslationCustomFieldName: string;
    contentfulXtmLanguagesMap: { [index: string]: string };
}

export interface UpdateClientDTO extends Serializable {
    clientName: string;
    status: boolean;
    enabledTags: boolean;
    enabledEntryLevelLocalization: boolean;
    enabledOldHyperLinks: boolean;
    nonHumanTranslationCustomFieldName: string;
    contentfulXtmLanguagesMap: { [index: string]: string };
}

export interface ClientDTO extends BaseDTO, Serializable {
    clientName: string;
    status: boolean;
    enabledTags: boolean;
    enabledEntryLevelLocalization: boolean;
    enabledOldHyperLinks: boolean;
    nonHumanTranslationCustomFieldName: string;
    contentfulXtmLanguagesMap: { [index: string]: string };
}

export interface EntriesRequestDTO extends Serializable {
    entriesIds: string[];
}

export interface ClientPreferencesDTO extends Serializable {
    entryLevelLocalization: boolean;
}

export interface EntriesDTO extends Serializable {
    total: number;
    entries: EntryDTO[];
}

export interface ContentfulConnectionDTO extends Serializable {
    accessToken: string;
}

export interface XTMConnectDTO extends Serializable {
    xtmAuthorizationId: string;
}

export interface XTMProjectDTO extends Serializable {
    id: number;
    name: string;
    status: string;
    description: string;
    dueDate: string;
    entryGroupingType: EntryGroupingType;
    activityEnum: ActivityEnum;
}

export interface ActiveProjectSearchDTO extends Serializable {
    sourceLanguage: string;
    targetLanguages: string[];
    customerId: number;
}

export interface XTMProjectVerifyDTO extends Serializable {
    customerId: number;
    templateId: number;
    spaceId: string;
    environmentId: string;
    entryIds: string[];
    sourceLanguage: string;
    targetLanguages: string[];
}

export interface XTMProjectEntryVerifyDTO extends Serializable {
    entries: XTMProjectEntriesVerifyDTO[];
    haveProjects: boolean;
}

export interface AllLanguagesDTO extends Serializable {
    allContentfulLanguages: string[];
    allXtmLanguages: string[];
}

export interface MassOperationRequest extends Serializable {
    jobsIds: string[];
    operation: JobOperation;
}

export interface ResetPasswordDTO extends Serializable {
    email: string;
    language: string;
}

export interface VerifyTokenDTO extends Serializable {
    token: string;
}

export interface SettingsAuthorizationDTO extends Serializable {
    clientId: string;
    connectionSpaceId: string;
    cmaToken: string[];
}

export interface SaveOrganizationSettingsDTO extends Serializable {
    clientId: string;
    spaceId: string;
    environmentId: string;
    allowedContentTypesIds: string[];
    recreatedContentTypesIds: string[];
    entryLevelLocalizationContentTypesIds: string[];
    normalTranslationContentTypesIds: string[];
    defaultEntryGroupingType: EntryGroupingType;
    allowUserChangeEntryGrouping: boolean;
    handleMultipleReferences: HandleMultipleReferencesType;
    referenceFetchLevel: ReferenceFetchLevel;
}

export interface UserSettingsDTO extends Serializable {
    allowUserChangeEntryGrouping: boolean;
    defaultEntryGroupingType: EntryGroupingType;
    xtmProjectNamePrefix: string;
}

export interface UserDTO extends BaseDTO, Serializable {
    email: string;
    status: boolean;
    clientName: string;
    client: ClientDTO;
    xtmProperties: UserXTMPropertiesDTO;
}

export interface BaseDTO extends Serializable {
    id: string;
    createdAt: Date;
    modifiedAt: Date;
}

export interface Serializable {
}

export interface Page<T> extends Slice<T> {
    totalPages: number;
    totalElements: number;
}

export interface EntryDTO extends Serializable {
    id: string;
    fields: { [index: string]: { [index: string]: any } };
    contentType: string;
    tags: string[];
    updatedAt: string;
    createdAt: string;
    publishedAt: string;
    archivedVersion: number;
    updatedBy: string;
}

export interface ReferenceDTO extends Serializable {
    id: string;
    name: string;
    contentType: string;
    entryType: string;
    status: EntryStatus;
    alreadyReferenced: boolean;
    hasMoreReferences: boolean;
    isEnabled: boolean;
    references: ReferenceDTO[];
}

export interface TagDTO extends Serializable {
    id: string;
    name: string;
}

export interface XTMProjectEntriesVerifyDTO extends Serializable {
    entryId: string;
    entryName: string;
    projectId: number;
}

export interface XTMCustomerDTO extends Serializable {
    xtmCustomerId: number;
    name: string;
}

export interface XTMTemplateDTO extends Serializable {
    xtmTemplateId: number;
    name: string;
}

export interface XTMJobDTO extends BaseDTO {
    projectName: string;
    sourceLanguage: string;
    targetLanguages: string[];
    errorMessage: string;
    errorMessagesDetails: string[];
    status: XTMJobStatus;
    workflowStep: string;
    jobProgress: number;
    dueDate: Date;
    entryId: string;
    projectId: number;
    customerId: number;
    templateId: number;
    filename: string;
}

export interface NotificationDTO extends Serializable {
    notificationType: NotificationType;
    jsonObject: string;
    sourceLanguage: string;
    targetLanguages: string[];
    entryId: string;
}

export interface SettingsDTO extends Serializable {
    settingsId: string;
    spaceId: string;
    spaceName: string;
    enabledEntryLevelLocalization: boolean;
    apiKeyError: boolean;
    spaceDeletedInContentful: boolean;
    organizationSettingsDTOList: OrganizationSettingsDTO[];
}

export interface PageableSettingsDTO extends Serializable {
    id: string;
    clientName: string;
    createdAt: Date;
    modifiedAt: Date;
    settingsInitialized: boolean;
}

export interface UserOverviewDTO extends BaseDTO, Serializable {
    email: string;
    status: boolean;
    clientName: string;
}

export interface UserXTMPropertiesDTO extends BaseDTO {
    xtmCustomerId: number;
    xtmTemplateId: number;
    xtmProjectNamePrefix: string;
    includeEmailToProjectName: boolean;
    removeXTMFilesAfterDays: number;
    removeCancelledJobsAfterDays: number;
}

export interface Sort extends Streamable<Order>, Serializable {
    sorted: boolean;
    unsorted: boolean;
}

export interface Pageable {
    sort: Sort;
    offset: number;
    paged: boolean;
    unpaged: boolean;
    pageNumber: number;
    pageSize: number;
}

export interface OrganizationSettingsDTO extends Serializable {
    organizationSettingsId: string;
    spaceId: string;
    environmentId: string;
    allowedContentTypesIds: string[];
    recreatedContentTypesIds: string[];
    entryLevelLocalizationContentTypesIds: string[];
    normalTranslationContentTypesIds: string[];
    availableContentTypes: ContentTypeDTO[];
    defaultEntryGroupingType: EntryGroupingType;
    allowUserChangeEntryGrouping: boolean;
    handleMultipleReferences: HandleMultipleReferencesType;
    referenceFetchLevel: ReferenceFetchLevel;
}

export interface Slice<T> extends Streamable<T> {
    number: number;
    sort: Sort;
    size: number;
    content: T[];
    pageable: Pageable;
    numberOfElements: number;
    first: boolean;
    last: boolean;
}

export interface ContentTypeDTO extends Serializable {
    contentTypeId: string;
    contentTypeName: string;
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
    empty: boolean;
}

export interface Order extends Serializable {
    direction: Direction;
    property: string;
    ignoreCase: boolean;
    nullHandling: NullHandling;
    descending: boolean;
    ascending: boolean;
}

export interface Iterable<T> {
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel: boolean;
}

export interface AutoCloseable {
}

export interface RestApplication<O> {

    /**
     * HTTP GET /
     * Java method: cloud.xtm.contentful.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string>;

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.contentful.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>>;

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO>;

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult>;

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult>;

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]>;

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>>;

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: UpdateClientDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO>;

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO>;

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO>;

    /**
     * HTTP GET /api/content/project/active/{projectId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjectDetails
     */
    getActiveXTMProjectDetails(projectId: number, options?: O): RestResponse<XTMProjectDTO>;

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]>;

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO>;

    /**
     * HTTP POST /api/contentful/add-to-queue
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.addToQueue
     */
    addToQueue(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/contentful/compare/space/{spaceId}/environments/{environmentId}/entries/{entryId}
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.compareContent
     */
    compareContent(spaceId: string, environmentId: string, entryId: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP POST /api/contentful/connection/verify
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.verifyConnection
     */
    verifyConnection(contentfulConnection: ContentfulConnectionDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/contentful/entries/search
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getEntries
     */
    getEntries(entryRequestDTO: EntryRequestDTO, options?: O): RestResponse<EntriesDTO>;

    /**
     * HTTP POST /api/contentful/space/{spaceId}/environments/{environmentId}/client-preferences
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getClientPreferences
     */
    getClientPreferences(spaceId: string, environmentId: string, request: EntriesRequestDTO, options?: O): RestResponse<ClientPreferencesDTO>;

    /**
     * HTTP POST /api/contentful/space/{spaceId}/environments/{environmentId}/references
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getReferences
     */
    getReferences(spaceId: string, environmentId: string, request: EntriesRequestDTO, queryParams?: { locale?: string; }, options?: O): RestResponse<ReferenceDTO[]>;

    /**
     * HTTP GET /api/contentful/space/{spaceId}/environments/{environmentId}/tags
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getTags
     */
    getTags(spaceId: string, environmentId: string, options?: O): RestResponse<TagDTO[]>;

    /**
     * HTTP POST /api/contentful/translate
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findAllPaged
     */
    findAllPaged(queryParams: { spaceId: string; environmentId: string; forceRefresh?: boolean; projectName?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<XTMJobDTO>>;

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/jobs/{jobId}/operation-applicable-for
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType(jobId: string, queryParams: { jobOperation: JobOperation; }, options?: O): RestResponse<string[]>;

    /**
     * HTTP GET /api/jobs/{jobId}/project-related-jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findJobsWithinSameProject
     */
    findJobsWithinSameProject(jobId: string, options?: O): RestResponse<XTMJobDTO[]>;

    /**
     * HTTP POST /api/jobs/{jobId}/validate
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.validate
     */
    validate(jobId: string, queryParams: { errorMessageCode: string; }, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/languages
     * Java method: cloud.xtm.contentful.backend.integration.xtm.languages.api.LanguagesController.getAllContentfulAndXtmLanguages
     */
    getAllContentfulAndXtmLanguages(options?: O): RestResponse<AllLanguagesDTO>;

    /**
     * HTTP GET /api/notifications
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.getAllNotifications
     */
    getAllNotifications(options?: O): RestResponse<NotificationDTO[]>;

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.fetchSettings
     */
    fetchSettings(options?: O): RestResponse<SettingsDTO[]>;

    /**
     * HTTP POST /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.saveSettings
     */
    saveSettings(saveOrganizationSettingsDTO: SaveOrganizationSettingsDTO, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.updateSettingsCredentials
     */
    updateSettingsCredentials(settingsAuthorizationDTO: SettingsAuthorizationDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/settings/allowed-content-types/space/{spaceId}/environment/{environmentId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.allowedContentTypes
     */
    allowedContentTypes(spaceId: string, environmentId: string, options?: O): RestResponse<string[]>;

    /**
     * HTTP POST /api/settings/initialize
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.initializeNewSettings
     */
    initializeNewSettings(settingsAuthorizationDTO: SettingsAuthorizationDTO, options?: O): RestResponse<SettingsDTO[]>;

    /**
     * HTTP GET /api/settings/is-initizalized
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.isSettingsInitialized
     */
    isSettingsInitialized$GET$api_settings_isinitizalized(options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/settings/is-initizalized/{clientId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.isSettingsInitialized
     */
    isSettingsInitialized$GET$api_settings_isinitizalized_clientId(clientId: string, options?: O): RestResponse<boolean>;

    /**
     * HTTP GET /api/settings/pageable
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.getAllSettings
     */
    getAllSettings(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<PageableSettingsDTO>>;

    /**
     * HTTP DELETE /api/settings/{settingsId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.deleteOrganizationSettings
     */
    deleteOrganizationSettings(settingsId: string, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/settings/{settingsId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.getSettings
     */
    getSettings(settingsId: string, options?: O): RestResponse<SettingsDTO[]>;

    /**
     * HTTP POST /api/user
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/user/pageable
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>>;

    /**
     * HTTP GET /api/user/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.UserSettingsController.getUserSettings
     */
    getUserSettings(queryParams?: { spaceId?: string; environmentId?: string; }, options?: O): RestResponse<UserSettingsDTO>;

    /**
     * HTTP DELETE /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void>;

    /**
     * HTTP PUT /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void>;

    /**
     * HTTP GET /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO>;

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void>;

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO>;

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO>;

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.contentful.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerDTO[]>;

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerDTO[]>;

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.contentful.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]>;

    /**
     * HTTP GET /api/xtm/templates/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_xtm_templates_userId(userId: string, queryParams: { xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]>;
}

export interface HttpClient<O> {

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: O; }): RestResponse<R>;
}

export class RestApplicationClient<O> implements RestApplication {

    constructor(protected httpClient: HttpClient<O>) {
    }

    /**
     * HTTP GET /
     * Java method: cloud.xtm.contentful.backend.common.app.api.RootController.getRoot
     */
    getRoot(options?: O): RestResponse<string> {
        return this.httpClient.request({ method: "GET", url: uriEncoding``, options: options });
    }

    /**
     * HTTP GET /api
     * Java method: cloud.xtm.contentful.backend.common.app.api.RootController.getApiRoot
     */
    getApiRoot(options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api`, options: options });
    }

    /**
     * HTTP POST /api/administrators
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.createAdministrator
     */
    createAdministrator(createAdministrator: CreateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/administrators`, data: createAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/pageable
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.getAllAdministrators
     */
    getAllAdministrators(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<AdministratorDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.deleteAdministrator
     */
    deleteAdministrator(administratorId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP PUT /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.editAdministrator
     */
    editAdministrator(administratorId: string, updateAdministrator: UpdateAdministratorDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/administrators/${administratorId}`, data: updateAdministrator, options: options });
    }

    /**
     * HTTP GET /api/administrators/{administratorId}
     * Java method: cloud.xtm.contentful.backend.administrators.app.api.ClientAdministratorController.getAdministrator
     */
    getAdministrator(administratorId: string, options?: O): RestResponse<AdministratorDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/administrators/${administratorId}`, options: options });
    }

    /**
     * HTTP POST /api/auth/admin
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.authenticateAdmin
     */
    authenticateAdmin(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/admin`, data: authRequest, options: options });
    }

    /**
     * HTTP GET /api/auth/refreshToken
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.refreshToken
     */
    refreshToken(options?: O): RestResponse<RefreshTokenResult> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/auth/refreshToken`, options: options });
    }

    /**
     * HTTP POST /api/auth/user
     * Java method: cloud.xtm.contentful.backend.auth.app.api.AuthController.authenticateUser
     */
    authenticateUser(authRequest: AuthenticationRequest, options?: O): RestResponse<AuthenticationResult> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/auth/user`, data: authRequest, options: options });
    }

    /**
     * HTTP POST /api/client
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.createClient
     */
    createClient(createClient: CreateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/client`, data: createClient, options: options });
    }

    /**
     * HTTP GET /api/client
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client(options?: O): RestResponse<ClientDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client`, options: options });
    }

    /**
     * HTTP GET /api/client/pageable
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getAllClients
     */
    getAllClients$GET$api_client_pageable(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<ClientDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.deleteClient
     */
    deleteClient(clientId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP PUT /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.editClient
     */
    editClient(clientId: string, updateClient: UpdateClientDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/client/${clientId}`, data: updateClient, options: options });
    }

    /**
     * HTTP GET /api/client/{clientId}
     * Java method: cloud.xtm.contentful.backend.clients.app.api.ClientController.getClient
     */
    getClient(clientId: string, options?: O): RestResponse<ClientDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/client/${clientId}`, options: options });
    }

    /**
     * HTTP GET /api/content/customers
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getXTMCustomers
     */
    getXTMCustomers$GET$api_content_customers(options?: O): RestResponse<GetCustomersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/customers`, options: options });
    }

    /**
     * HTTP POST /api/content/entries/verify
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.verify
     */
    verify(xtmProject: XTMProjectVerifyDTO, options?: O): RestResponse<XTMProjectEntryVerifyDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/entries/verify`, data: xtmProject, options: options });
    }

    /**
     * HTTP GET /api/content/project/active/{projectId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjectDetails
     */
    getActiveXTMProjectDetails(projectId: number, options?: O): RestResponse<XTMProjectDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/project/active/${projectId}`, options: options });
    }

    /**
     * HTTP POST /api/content/projects/active
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getActiveXTMProjects
     */
    getActiveXTMProjects(activeProjectSearch: ActiveProjectSearchDTO, options?: O): RestResponse<XTMProjectDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/content/projects/active`, data: activeProjectSearch, options: options });
    }

    /**
     * HTTP GET /api/content/templates/{xtmCustomerId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.content.api.XTMAddContentController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_content_templates_xtmCustomerId(xtmCustomerId: number, options?: O): RestResponse<GetTemplatesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/content/templates/${xtmCustomerId}`, options: options });
    }

    /**
     * HTTP POST /api/contentful/add-to-queue
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.addToQueue
     */
    addToQueue(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/add-to-queue`, data: translateRequest, options: options });
    }

    /**
     * HTTP GET /api/contentful/compare/space/{spaceId}/environments/{environmentId}/entries/{entryId}
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.compareContent
     */
    compareContent(spaceId: string, environmentId: string, entryId: string, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/contentful/compare/space/${spaceId}/environments/${environmentId}/entries/${entryId}`, options: options });
    }

    /**
     * HTTP POST /api/contentful/connection/verify
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.verifyConnection
     */
    verifyConnection(contentfulConnection: ContentfulConnectionDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/connection/verify`, data: contentfulConnection, options: options });
    }

    /**
     * HTTP POST /api/contentful/entries/search
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getEntries
     */
    getEntries(entryRequestDTO: EntryRequestDTO, options?: O): RestResponse<EntriesDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/entries/search`, data: entryRequestDTO, options: options });
    }

    /**
     * HTTP POST /api/contentful/space/{spaceId}/environments/{environmentId}/client-preferences
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getClientPreferences
     */
    getClientPreferences(spaceId: string, environmentId: string, request: EntriesRequestDTO, options?: O): RestResponse<ClientPreferencesDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/space/${spaceId}/environments/${environmentId}/client-preferences`, data: request, options: options });
    }

    /**
     * HTTP POST /api/contentful/space/{spaceId}/environments/{environmentId}/references
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getReferences
     */
    getReferences(spaceId: string, environmentId: string, request: EntriesRequestDTO, queryParams?: { locale?: string; }, options?: O): RestResponse<ReferenceDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/space/${spaceId}/environments/${environmentId}/references`, queryParams: queryParams, data: request, options: options });
    }

    /**
     * HTTP GET /api/contentful/space/{spaceId}/environments/{environmentId}/tags
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.getTags
     */
    getTags(spaceId: string, environmentId: string, options?: O): RestResponse<TagDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/contentful/space/${spaceId}/environments/${environmentId}/tags`, options: options });
    }

    /**
     * HTTP POST /api/contentful/translate
     * Java method: cloud.xtm.contentful.backend.contentful.app.api.ContentfulController.translate
     */
    translate(translateRequest: TranslateRequestDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/contentful/translate`, data: translateRequest, options: options });
    }

    /**
     * HTTP GET /api/jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findAllPaged
     */
    findAllPaged(queryParams: { spaceId: string; environmentId: string; forceRefresh?: boolean; projectName?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<XTMJobDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/jobs`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.performOperation
     */
    performOperation(request: MassOperationRequest, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs`, data: request, options: options });
    }

    /**
     * HTTP GET /api/jobs/{jobId}/operation-applicable-for
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findJobsIdsByOperationType
     */
    findJobsIdsByOperationType(jobId: string, queryParams: { jobOperation: JobOperation; }, options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/jobs/${jobId}/operation-applicable-for`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/jobs/{jobId}/project-related-jobs
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.findJobsWithinSameProject
     */
    findJobsWithinSameProject(jobId: string, options?: O): RestResponse<XTMJobDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/jobs/${jobId}/project-related-jobs`, options: options });
    }

    /**
     * HTTP POST /api/jobs/{jobId}/validate
     * Java method: cloud.xtm.contentful.backend.jobs.app.api.JobsController.validate
     */
    validate(jobId: string, queryParams: { errorMessageCode: string; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/jobs/${jobId}/validate`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/languages
     * Java method: cloud.xtm.contentful.backend.integration.xtm.languages.api.LanguagesController.getAllContentfulAndXtmLanguages
     */
    getAllContentfulAndXtmLanguages(options?: O): RestResponse<AllLanguagesDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/languages`, options: options });
    }

    /**
     * HTTP GET /api/notifications
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.getAllNotifications
     */
    getAllNotifications(options?: O): RestResponse<NotificationDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/notifications`, options: options });
    }

    /**
     * HTTP POST /api/notifications/admin/send-reset-password-email
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.sendResetPasswordEmailForAdmin
     */
    sendResetPasswordEmailForAdmin(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/admin/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/change-password
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.changePassword
     */
    changePassword(changePassword: ChangePasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/change-password`, data: changePassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/send-reset-password-email
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.sendResetPasswordEmail
     */
    sendResetPasswordEmail(resetPassword: ResetPasswordDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/send-reset-password-email`, data: resetPassword, options: options });
    }

    /**
     * HTTP POST /api/notifications/verify-reset-password-token
     * Java method: cloud.xtm.contentful.backend.notification.app.api.NotificationController.verifyResetPasswordToken
     */
    verifyResetPasswordToken(verifyToken: VerifyTokenDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/notifications/verify-reset-password-token`, data: verifyToken, options: options });
    }

    /**
     * HTTP GET /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.fetchSettings
     */
    fetchSettings(options?: O): RestResponse<SettingsDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings`, options: options });
    }

    /**
     * HTTP POST /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.saveSettings
     */
    saveSettings(saveOrganizationSettingsDTO: SaveOrganizationSettingsDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/settings`, data: saveOrganizationSettingsDTO, options: options });
    }

    /**
     * HTTP PUT /api/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.updateSettingsCredentials
     */
    updateSettingsCredentials(settingsAuthorizationDTO: SettingsAuthorizationDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/settings`, data: settingsAuthorizationDTO, options: options });
    }

    /**
     * HTTP GET /api/settings/allowed-content-types/space/{spaceId}/environment/{environmentId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.allowedContentTypes
     */
    allowedContentTypes(spaceId: string, environmentId: string, options?: O): RestResponse<string[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings/allowed-content-types/space/${spaceId}/environment/${environmentId}`, options: options });
    }

    /**
     * HTTP POST /api/settings/initialize
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.initializeNewSettings
     */
    initializeNewSettings(settingsAuthorizationDTO: SettingsAuthorizationDTO, options?: O): RestResponse<SettingsDTO[]> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/settings/initialize`, data: settingsAuthorizationDTO, options: options });
    }

    /**
     * HTTP GET /api/settings/is-initizalized
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.isSettingsInitialized
     */
    isSettingsInitialized$GET$api_settings_isinitizalized(options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings/is-initizalized`, options: options });
    }

    /**
     * HTTP GET /api/settings/is-initizalized/{clientId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.isSettingsInitialized
     */
    isSettingsInitialized$GET$api_settings_isinitizalized_clientId(clientId: string, options?: O): RestResponse<boolean> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings/is-initizalized/${clientId}`, options: options });
    }

    /**
     * HTTP GET /api/settings/pageable
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.getAllSettings
     */
    getAllSettings(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<PageableSettingsDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/settings/{settingsId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.deleteOrganizationSettings
     */
    deleteOrganizationSettings(settingsId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/settings/${settingsId}`, options: options });
    }

    /**
     * HTTP GET /api/settings/{settingsId}
     * Java method: cloud.xtm.contentful.backend.settings.app.api.SettingsController.getSettings
     */
    getSettings(settingsId: string, options?: O): RestResponse<SettingsDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/settings/${settingsId}`, options: options });
    }

    /**
     * HTTP POST /api/user
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.createUser
     */
    createUser(createUser: CreateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/user`, data: createUser, options: options });
    }

    /**
     * HTTP GET /api/user/pageable
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.getAllUsers
     */
    getAllUsers(queryParams?: { search?: string; page?: number; size?: number; sort?: string; }, options?: O): RestResponse<Page<UserOverviewDTO>> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/user/pageable`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/user/settings
     * Java method: cloud.xtm.contentful.backend.settings.app.api.UserSettingsController.getUserSettings
     */
    getUserSettings(queryParams?: { spaceId?: string; environmentId?: string; }, options?: O): RestResponse<UserSettingsDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/user/settings`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP DELETE /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.deleteUser
     */
    deleteUser(userId: string, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "DELETE", url: uriEncoding`api/user/${userId}`, options: options });
    }

    /**
     * HTTP PUT /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.editUser
     */
    editUser(userId: string, updateUser: UpdateUserDTO, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "PUT", url: uriEncoding`api/user/${userId}`, data: updateUser, options: options });
    }

    /**
     * HTTP GET /api/user/{userId}
     * Java method: cloud.xtm.contentful.backend.users.app.api.UserController.getUser
     */
    getUser(userId: string, options?: O): RestResponse<UserDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/user/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/callbacks/jobs/{clientId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.callback.app.api.XTMCallbackController.getJobFinishedCallback
     */
    getJobFinishedCallback(clientId: string, queryParams: { xtmJobId: number; xtmProjectId: number; }, options?: O): RestResponse<void> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/callbacks/jobs/${clientId}`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.connectToXtm
     */
    connectToXtm(xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/connect/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.getXTMConnectionParameters
     */
    getXTMConnectionParameters(userId: string, options?: O): RestResponse<XTMConnectionParametersDTO> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/connect/${userId}`, options: options });
    }

    /**
     * HTTP POST /api/xtm/connect/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.auth.app.api.XTMConnectController.reconnectToXtm
     */
    reconnectToXtm(userId: string, xtmConnectRequest: XTMConnectRequest, options?: O): RestResponse<XTMConnectDTO> {
        return this.httpClient.request({ method: "POST", url: uriEncoding`api/xtm/connect/${userId}`, data: xtmConnectRequest, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers
     * Java method: cloud.xtm.contentful.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomers
     */
    getXTMCustomers$GET$api_xtm_customers(queryParams: { xtmAuthId: string; }, options?: O): RestResponse<XTMCustomerDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/customers/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.customers.app.api.XTMCustomersController.getXTMCustomersForExistingUser
     */
    getXTMCustomersForExistingUser(userId: string, options?: O): RestResponse<XTMCustomerDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/customers/${userId}`, options: options });
    }

    /**
     * HTTP GET /api/xtm/templates
     * Java method: cloud.xtm.contentful.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplates
     */
    getXTMTemplates(queryParams: { xtmAuthId: string; xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/templates`, queryParams: queryParams, options: options });
    }

    /**
     * HTTP GET /api/xtm/templates/{userId}
     * Java method: cloud.xtm.contentful.backend.integration.xtm.templates.app.api.XTMTemplatesController.getXTMTemplatesForExistingUser
     */
    getXTMTemplatesForExistingUser$GET$api_xtm_templates_userId(userId: string, queryParams: { xtmCustomerId: number; }, options?: O): RestResponse<XTMTemplateDTO[]> {
        return this.httpClient.request({ method: "GET", url: uriEncoding`api/xtm/templates/${userId}`, queryParams: queryParams, options: options });
    }
}

export type RestResponse<R> = Promise<Axios.GenericAxiosResponse<R>>;

export type EntryStatus = "CHANGED" | "PUBLISHED" | "DRAFT" | "ARCHIVED";

export type JobOperation = "SEND" | "CANCEL" | "DELETE" | "IMPORT";

export type EntryGroupingType = "ALL_IN_ONE_FILE" | "ENTRY_GROUPING" | "SEPARATELY";

export type ActivityEnum = "ACTIVE" | "ARCHIVED" | "AUTO_ARCHIVED" | "DELETED" | "INACTIVE";

export type HandleMultipleReferencesType = "DO_NOT_REPEAT" | "REPEAT";

export type ReferenceFetchLevel = "LEVEL_0" | "LEVEL_1" | "LEVEL_2" | "LEVEL_3" | "LEVEL_4" | "LEVEL_5" | "LEVEL_6" | "LEVEL_7" | "LEVEL_8" | "LEVEL_9" | "LEVEL_10";

export type XTMJobStatus = "SENDING" | "NEW" | "SENT" | "IN_PROGRESS" | "FINISHED" | "CANCELLED" | "FETCHED";

export type NotificationType = "ASYNCHRONOUS_TRANSLATION_ERROR" | "ASYNCHRONOUS_TRANSLATION_SUCCESS";

export type Direction = "ASC" | "DESC";

export type NullHandling = "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";

function uriEncoding(template: TemplateStringsArray, ...substitutions: any[]): string {
    let result = "";
    for (let i = 0; i < substitutions.length; i++) {
        result += template[i];
        result += encodeURIComponent(substitutions[i]);
    }
    result += template[template.length - 1];
    return result;
}


// Added by 'AxiosClientExtension' extension

import axios from "axios";
import * as Axios from "axios";

declare module "axios" {
    export interface GenericAxiosResponse<R> extends Axios.AxiosResponse {
        data: R;
    }
}

class AxiosHttpClient implements HttpClient<Axios.AxiosRequestConfig> {

    constructor(private axios: Axios.AxiosInstance) {
    }

    request<R>(requestConfig: { method: string; url: string; queryParams?: any; data?: any; copyFn?: (data: R) => R; options?: Axios.AxiosRequestConfig; }): RestResponse<R> {
        function assign(target: any, source?: any) {
            if (source != undefined) {
                for (const key in source) {
                    if (source.hasOwnProperty(key)) {
                        target[key] = source[key];
                    }
                }
            }
            return target;
        }

        const config: Axios.AxiosRequestConfig = {};
        config.method = requestConfig.method as typeof config.method;  // `string` in axios 0.16.0, `Method` in axios 0.19.0
        config.url = requestConfig.url;
        config.params = requestConfig.queryParams;
        config.data = requestConfig.data;
        assign(config, requestConfig.options);
        const copyFn = requestConfig.copyFn;

        const axiosResponse = this.axios.request(config);
        return axiosResponse.then(axiosResponse => {
            if (copyFn && axiosResponse.data) {
                (axiosResponse as any).originalData = axiosResponse.data;
                axiosResponse.data = copyFn(axiosResponse.data);
            }
            return axiosResponse;
        });
    }
}

export class AxiosRestApplicationClient extends RestApplicationClient<Axios.AxiosRequestConfig> {

    constructor(baseURL: string, axiosInstance: Axios.AxiosInstance = axios.create()) {
        axiosInstance.defaults.baseURL = baseURL;
        super(new AxiosHttpClient(axiosInstance));
    }
}
